<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
          <form class="form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom card-sticky">
              <div class="card-header" style="">
                <div class="card-title">
                  <h3 class="card-label">{{ $t("ADD.ADD_CONSTRUCTION") }}</h3>
                </div>
                <CardToolbar
                  @submitEvent="onSubmit"
                  back-url="/constructions/list"
                  buttonText="Add Construction"
                ></CardToolbar>
              </div>
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <div class="row justify-content-center">
                      <div class="col-xl-9">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("LIST.NAME") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                id="name"
                                class="form-control form-control-lg"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="construction.name"
                                name="name"
                                type="text"
                                :placeholder="$t('GENERAL.NAME')"
                              />
                              <!--                              <div v-if="errors[0]" class="invalid-feedback">-->
                              <!--                                {{ errors[0] }}-->
                              <!--                              </div>-->
                            </div>
                          </div>
                        </validation-provider>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.ADDRESS") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <input
                              id="firstName"
                              class="form-control"
                              v-model="construction.address"
                              name="name"
                              type="text"
                              :placeholder="$t('GENERAL.ADDRESS')"
                            />
                          </div>
                        </div>

                        <div class="separator separator-dashed my-10"></div>

                        <!--                      <h5 class="text-dark font-weight-bold mb-10">-->
                        <!--                        Salary & Hourly Rate-->
                        <!--                      </h5>-->

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("ADD.ADD_PROOF") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <vc-date-picker
                              v-model="construction.proof"
                              :masks="masks"
                            >
                              <template v-slot="{ inputValue, togglePopover }">
                                <div
                                  class="input-group"
                                  @click="togglePopover()"
                                >
                                  <input
                                    class="form-control"
                                    :value="inputValue"
                                    name="proof"
                                    type="text"
                                  />
                                  <div
                                    class="input-group-prepend cursor-pointer"
                                  >
                                    <span class="input-group-text"
                                      ><i class="la la-calendar"></i
                                    ></span>
                                  </div>
                                </div>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("ADD.ADD_BILL") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <vc-date-picker
                              v-model="construction.bill"
                              :masks="masks"
                            >
                              <template v-slot="{ inputValue, togglePopover }">
                                <div
                                  class="input-group"
                                  @click="togglePopover()"
                                >
                                  <input
                                    class="form-control"
                                    :value="inputValue"
                                    name="bill"
                                    type="text"
                                  />
                                  <div
                                    class="input-group-prepend cursor-pointer"
                                  >
                                    <span class="input-group-text"
                                      ><i class="la la-calendar"></i
                                    ></span>
                                  </div>
                                </div>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.CHARGED") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <input
                                class="form-control"
                                v-model="construction.charged"
                                name="salary"
                                type="text"
                                :placeholder="$t('GENERAL.CHARGED')"
                              />
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  ><i class="la la-euro"></i
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.START") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <vc-date-picker
                              v-model="construction.started"
                              :masks="masks"
                            >
                              <template v-slot="{ inputValue, togglePopover }">
                                <div
                                  class="input-group"
                                  @click="togglePopover()"
                                >
                                  <input
                                    class="form-control"
                                    :value="inputValue"
                                    name="bill"
                                    type="text"
                                  />
                                  <div
                                    class="input-group-prepend cursor-pointer"
                                  >
                                    <span class="input-group-text"
                                      ><i class="la la-calendar"></i
                                    ></span>
                                  </div>
                                </div>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.END") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <vc-date-picker
                              v-model="construction.finished"
                              :masks="masks"
                            >
                              <template v-slot="{ inputValue, togglePopover }">
                                <div
                                  class="input-group"
                                  @click="togglePopover()"
                                >
                                  <input
                                    class="form-control"
                                    :value="inputValue"
                                    name="bill"
                                    type="text"
                                  />
                                  <div
                                    class="input-group-prepend cursor-pointer"
                                  >
                                    <span class="input-group-text"
                                      ><i class="la la-calendar"></i
                                    ></span>
                                  </div>
                                </div>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>

                        <div class="separator separator-dashed my-10"></div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.NOTE") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <textarea
                              class="form-control"
                              v-model="construction.description"
                              rows="4"
                              :placeholder="$t('GENERAL.NOTE')"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CardToolbar from "@/view/content/components/card-toolbar/CardToolbar.vue";
import ApiService from "@/core/services/api.service";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "",
});

export default {
  name: "NewConstruction",
  components: { CardToolbar, ValidationProvider, ValidationObserver },
  data() {
    return {
      construction: {
        name: null,
        description: null,
        address: null,
        charged: null,
        proof: null,
        bill: null,
        started: null,
        finished: null,
      },
      masks: {
        input: "DD.MM:YYYY.",
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.ALL_CONSTRUCTIONS"),
        route: "/constructions/list",
      },
      { title: this.$t("LIST.NEW_CONSTRUCTION") },
    ]);

    document.getElementById("name").focus();
  },
  methods: {
    onSubmit(type) {
      this.$refs.observerRef.validate().then((isValid) => {
        if (isValid) {
          ApiService.post("constructions", this.construction)
            .then(() => {
              this.$notify({
                group: "notification",
                type: "success",
                title: this.$t("CONSTRUCTIONS.ADDED"),
              });
              if (type === "1") {
                setTimeout(() => {
                  this.$router.push({ name: "constructions-list" });
                }, 1000);
              }
            })
            .catch((error) => {
              this.$notify({
                group: "notification",
                type: "error",
                title: error,
              });
            });
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("USER.MANDATORY"),
          });
        }
      });
    },
  },
};
</script>
